export const rpDrawings = [
	{
		id: "1",
		title: "Simple",
		url: "./images/FacadeTypologyDrawings/Simple.png",
		category: "rpDrawingsSimple",
	},
	{
		id: "2",
		title: "Balcony",
		url: "./images/FacadeTypologyDrawings/Balcony.png",
		category: "rpDrawingsSimple",
	},
	{
		id: "3",
		title: "Awning",
		url: "./images/FacadeTypologyDrawings/Awning.png",
		category: "rpDrawingsAwning",
	},
	{
		id: "4",
		title: "Floor to Ceiling",
		url: "./images/FacadeTypologyDrawings/GulvLoft.png",
		category: "rpDrawingsFTC",
	},
	{
		id: "5",
		title: "Horizontal Louvers",
		url: "./images/FacadeTypologyDrawings/HorizontaleLister.png",
		category: "rpDrawingsHS",
	},
	{
		id: "6",
		title: "Bay",
		url: "./images/FacadeTypologyDrawings/Bay.png",
		category: "rpDrawingsBay",
	},
	{
		id: "7",
		title: "Vertical Louvers",
		url: "./images/FacadeTypologyDrawings/VerticalLister.png",
		category: "rpDrawingsVZ",
	},
];

export const rpPhotos = [
	{
		title: "Simple",
		image: "./images/FacadeTypologyPhotos/Simple/image-1.jpg",
	},
	{
		title: "Simple",
		image: "./images/FacadeTypologyPhotos/Simple/image-2.jpg",
	},
	{
		title: "Simple",
		image: "./images/FacadeTypologyPhotos/Simple/image-3.jpg",
	},
	{
		title: "Simple",
		image: "./images/FacadeTypologyPhotos/Simple/image-4.jpg",
	},
	{
		title: "Simple",
		image: "./images/FacadeTypologyPhotos/Simple/image-5.jpg",
	},
	{
		title: "Simple",
		image: "./images/FacadeTypologyPhotos/Simple/image-6.jpg",
	},
	{
		title: "Simple",
		image: "./images/FacadeTypologyPhotos/Simple/image-7.jpg",
	},
	{
		title: "Simple",
		image: "./images/FacadeTypologyPhotos/Simple/image-8.jpg",
	},
	{
		title: "Simple",
		image: "./images/FacadeTypologyPhotos/Simple/image-9.jpg",
	},
	{
		title: "Simple",
		image: "./images/FacadeTypologyPhotos/Simple/image-10.jpg",
	},
	{
		title: "Simple",
		image: "./images/FacadeTypologyPhotos/Simple/image-11.jpg",
	},
	{
		title: "Simple",
		image: "./images/FacadeTypologyPhotos/Simple/image-12.jpg",
	},
	{
		title: "Simple",
		image: "./images/FacadeTypologyPhotos/Simple/image-13.jpg",
	},
	{
		title: "Simple",
		image: "./images/FacadeTypologyPhotos/Simple/image-14.jpg",
	},
	{
		title: "Simple",
		image: "./images/FacadeTypologyPhotos/Simple/image-15.jpg",
	},
	{
		title: "Simple",
		image: "./images/FacadeTypologyPhotos/Simple/image-16.jpg",
	},
	{
		title: "Awning",
		image: "./images/FacadeTypologyPhotos/Awning/image1.jpg",
	},
	{
		title: "Awning",
		image: "./images/FacadeTypologyPhotos/Awning/image2.jpg",
	},
	{
		title: "Awning",
		image: "./images/FacadeTypologyPhotos/Awning/image3.jpg",
	},
	{
		title: "Awning",
		image: "./images/FacadeTypologyPhotos/Awning/image4.jpg",
	},
	{
		title: "Awning",
		image: "./images/FacadeTypologyPhotos/Awning/image5.jpg",
	},
	{
		title: "Awning",
		image: "./images/FacadeTypologyPhotos/Awning/image6.jpg",
	},
	{
		title: "Awning",
		image: "./images/FacadeTypologyPhotos/Awning/image7.jpg",
	},
	{
		title: "Awning",
		image: "./images/FacadeTypologyPhotos/Awning/image8.jpg",
	},
	{
		title: "Balcony",
		image: "./images/FacadeTypologyPhotos/Balcony/image1.jpg",
	},
	{
		title: "Balcony",
		image: "./images/FacadeTypologyPhotos/Balcony/image2.jpg",
	},
	{
		title: "Balcony",
		image: "./images/FacadeTypologyPhotos/Balcony/image3.jpg",
	},
	{
		title: "Balcony",
		image: "./images/FacadeTypologyPhotos/Balcony/image4.jpg",
	},
	{
		title: "Balcony",
		image: "./images/FacadeTypologyPhotos/Balcony/image5.jpg",
	},
	{
		title: "Balcony",
		image: "./images/FacadeTypologyPhotos/Balcony/image6.jpg",
	},
	{
		title: "Balcony",
		image: "./images/FacadeTypologyPhotos/Balcony/image7.jpg",
	},
	{
		title: "Balcony",
		image: "./images/FacadeTypologyPhotos/Balcony/image8.jpg",
	},
	{
		title: "Balcony",
		image: "./images/FacadeTypologyPhotos/Balcony/image9.jpg",
	},
	{
		title: "Balcony",
		image: "./images/FacadeTypologyPhotos/Balcony/image10.jpg",
	},
	{
		title: "Balcony",
		image: "./images/FacadeTypologyPhotos/Balcony/image11.jpg",
	},
	{
		title: "Balcony",
		image: "./images/FacadeTypologyPhotos/Balcony/image12.jpg",
	},
	{
		title: "Balcony",
		image: "./images/FacadeTypologyPhotos/Balcony/image13.jpg",
	},
	{
		title: "Balcony",
		image: "./images/FacadeTypologyPhotos/Balcony/image14.jpg",
	},
	{
		title: "Balcony",
		image: "./images/FacadeTypologyPhotos/Balcony/image15.jpg",
	},
	{
		title: "Balcony",
		image: "./images/FacadeTypologyPhotos/Balcony/image16.jpg",
	},
	{
		title: "Balcony",
		image: "./images/FacadeTypologyPhotos/Balcony/image17.jpg",
	},
	{
		title: "Balcony",
		image: "./images/FacadeTypologyPhotos/Balcony/image18.jpg",
	},
	{
		title: "Balcony",
		image: "./images/FacadeTypologyPhotos/Balcony/image19.jpg",
	},
	{
		title: "Floor to Ceiling",
		image: "./images/FacadeTypologyPhotos/FloorToCeiling/image1.jpg",
	},
	{
		title: "Floor to Ceiling",
		image: "./images/FacadeTypologyPhotos/FloorToCeiling/image2.jpg",
	},
	{
		title: "Floor to Ceiling",
		image: "./images/FacadeTypologyPhotos/FloorToCeiling/image3.jpg",
	},
	{
		title: "Floor to Ceiling",
		image: "./images/FacadeTypologyPhotos/FloorToCeiling/image4.jpg",
	},
	{
		title: "Floor to Ceiling",
		image: "./images/FacadeTypologyPhotos/FloorToCeiling/image5.jpg",
	},
	{
		title: "Floor to Ceiling",
		image: "./images/FacadeTypologyPhotos/FloorToCeiling/image6.jpg",
	},
	{
		title: "Floor to Ceiling",
		image: "./images/FacadeTypologyPhotos/FloorToCeiling/image7.jpg",
	},
	{
		title: "Floor to Ceiling",
		image: "./images/FacadeTypologyPhotos/FloorToCeiling/image8.jpg",
	},
	{
		title: "Floor to Ceiling",
		image: "./images/FacadeTypologyPhotos/FloorToCeiling/image9.jpg",
	},
	{
		title: "Floor to Ceiling",
		image: "./images/FacadeTypologyPhotos/FloorToCeiling/image10.jpg",
	},
	{
		title: "Floor to Ceiling",
		image: "./images/FacadeTypologyPhotos/FloorToCeiling/image11.jpg",
	},
	{
		title: "Floor to Ceiling",
		image: "./images/FacadeTypologyPhotos/FloorToCeiling/image12.jpg",
	},
	{
		title: "Floor to Ceiling",
		image: "./images/FacadeTypologyPhotos/FloorToCeiling/image13.jpg",
	},
	{
		title: "Floor to Ceiling",
		image: "./images/FacadeTypologyPhotos/FloorToCeiling/image14.jpg",
	},
	{
		title: "Floor to Ceiling",
		image: "./images/FacadeTypologyPhotos/FloorToCeiling/image15.jpg",
	},
	{
		title: "Floor to Ceiling",
		image: "./images/FacadeTypologyPhotos/FloorToCeiling/image16.jpg",
	},
	{
		title: "Horizontal Louvers",
		image: "./images/FacadeTypologyPhotos/HorizontalLouvers/image1.jpg",
	},
	{
		title: "Horizontal Louvers",
		image: "./images/FacadeTypologyPhotos/HorizontalLouvers/image2.jpg",
	},
];
