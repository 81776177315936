import "./RangeSlider.css";

const HowToUse = () => {
	return (
		<div className="HowToUse">
			<h3
				style={{
					display: "inline-block",
					fontWeight: "bold",
					fontSize: "1.3em",
					color: "white",
					color: "#f1356d",
					marginTop: "30px",
				}}
			>
				How to use
			</h3>

			<p>
				Explore different facade design choices and play around with the
				different models. Before that, however, here's a quick guide on how to
				utilize the tool.
			</p>

			<p>
				<strong>1. Adjust the variables&#58;</strong> <br></br> Use the range
				sliders to adjust the variables that affect the design, such as window
				size, type and placement.
			</p>

			<p>
				<strong>2. Observe real-time updated results&#58; </strong> <br></br> As
				you adjust the sliders, the tool will also calculate and display
				real-time results on factors such as energy use, natural lighting, and
				thermal indoor climate.
			</p>

			<p>
				<strong>3. Experiment with different options&#58;</strong> <br></br> Use
				the range sliders to experiment with different design options until you
				find the perfect balance between aesthetics and performance for your
				project.
			</p>

			<p>
				<strong>4. Save your favorite designs&#58; </strong> <br></br> If you
				find a design and respective results that you want to save, click the
				"save" button.
			</p>

			<p>
				<strong>5. Compare designs&#58;</strong> <br></br> In the overview
				section, you can compare multiple of your saved design choices
				side-by-side. This allows you to make informed decisions about which
				design options are the most suitable for your project.
			</p>

			<p>
				<strong>6. Autogenerate overview Report&#58; </strong> <br></br> Use the
				"Download Report" button to download your overview in PDF-format
				together with a detailed description of the website application, the
				performance indicators, and the simulation details.
			</p>
		</div>
	);
};

export default HowToUse;
