import useFetch from "./useFetch";
import {Link} from "react-router-dom";
<link
	rel="stylesheet"
	href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0"
/>;

const Home = () => {
	return (
		<div>
			<div className="frontpage">
				<h2>Reference Picture Database</h2>
				<div className="frontpagepicturecontainer">
					<div>
						<p className="db-description1">
							Reference Picture Database V.1.3, updated May 2023.
						</p>
						<p className="db-description2">
							Reference Picture Database (RPD) is a website application that
							provides a handpicked collection of typical facade typology
							examples. These examples are generated using Rhino Grasshopper
							with the assistance of extension plugins, namely ICEbear, Horster,
							and Colibri. ICEbear is responsible for conducting indoor climate
							simulations, while Horster and Colibri handles the iteration and
							data storage of these simulations. The models available on the
							website are based on pre-calculated scenarios, providing users
							with a range of ready-to-use design options for reference and
							analysis.
						</p>
						<p className="db-description3">
							This software has been developed by Thomas Anh Tan Vu as a part of
							his master's thesis in civil engineering at Aarhus University.
						</p>

						<Link
							to="/facadetypologies"
							style={{
								display: "inline-block",
								fontWeight: "bold",
								fontSize: "1.0em",
								color: "white",
								backgroundColor: "#f1356d",
								borderRadius: "8px",
								padding: "8px",
								paddingLeft: "16px",
								paddingRight: "16px",
								float: "right",
							}}
						>
							Go straight to facade selection
							<i className="arrow right" style={{marginLeft: "8px"}}></i>
						</Link>
					</div>
					<div>
						<img
							className="frontpagepicture"
							src={require("./icons/Facade typology.png")}
							alt="Facade Typologies"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Home;
