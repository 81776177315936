import Navbar from "./Navbar";
import Home from "./Home";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Contact from "./Contact";
import NotFound from "./NotFound";
import FacadeTypologies from "./FacadeTypologies";
import About from "./About";
import Tool from "./Tool";
import PDFGenerator from "./PDFGenerator";

function App() {
	return (
		<Router>
			<div className="App">
				<Navbar />
				<div className="content">
					<Switch>
						<Route exact path="/">
							<Home />
						</Route>

						<Route exact path="/contact">
							<Contact />
						</Route>

						<Route exact path="/about">
							<About />
						</Route>

						<Route exact path="/facadetypologies">
							<FacadeTypologies />
						</Route>

						<Route exact path="/Tool">
							<Tool />
						</Route>

						<Route exact path="/PDF">
							<PDFGenerator />
						</Route>

						<Route exact path="*">
							<NotFound />
						</Route>
					</Switch>
				</div>
			</div>
		</Router>
	);
}

export default App;
