import ImageRender from "./ImageRender";
import {useState} from "react";
import useFetch from "./useFetch";
import {Link} from "react-router-dom";
import "./RangeSlider.css";
import HowToUse from "./HowToUse";
import DropDownInfo from "./DropDownInformation";

// npx json-server --watch src/data/simple/simplemodel2.json --port 7000
// npx json-server --watch src/data/simple/simplemodel3.json --port 9000
// npx json-server --watch src/data/referencepictures.json --port 8000

//Every time a new image folder is in use the change should be used in the files - "ImageRender", "SimulationResults", and "PDFGenerator"

const Tool = () => {
	const {error, isPending, data} = useFetch(
		"https://simonvu.dk/simplemodel3.json"
		//"http://localhost:9000/imagesSimple"
	);

	console.log(data);

	return (
		<div className="Tool">
			<h2 className="page-title">Simple Model</h2>
			{error && <div> {error} </div>}
			{isPending && <div>Loading...</div>}
			{data && (
				<ImageRender
					images={data.imagesSimple != null ? data.imagesSimple : data}
				/>
			)}
			<DropDownInfo />
		</div>
	);
};

export default Tool;
