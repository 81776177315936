import {useState} from "react";
import {useHistory} from "react-router-dom";

const Contact = () => {
	const [title, setTitle] = useState("");
	const [body, setBody] = useState("");
	const [author, setAuthor] = useState("Rasmus Seebach");
	const history = useHistory();

	const handleSubmit = (e) => {
		e.preventDefault();
		const blog = {title, body, author};

		fetch("http://localhost:8000/blogs/", {
			method: "POST",
			headers: {"Content-Type": "application/json"},
			body: JSON.stringify(blog),
		}).then(() => {
			// history.go(-1);
			history.push("/");
		});
	};

	return (
		<div className="contact">
			<h2 className="page-title">Feedback</h2>
			<form onSubmit={handleSubmit}>
				<label>Title:</label>
				<input
					type="text"
					value={title}
					onChange={(e) => setTitle(e.target.value)}
				/>
				<label>Comment:</label>
				<textarea
					required
					value={body}
					onChange={(e) => setBody(e.target.value)}
				></textarea>
				<label>Occupation:</label>
				<select value={author} onChange={(e) => setAuthor(e.target.value)}>
					<option value="Engineer">Engineer</option>
					<option value="Architect">Architect</option>
					<option value="Designer">Designer</option>
					<option value="Other">Other</option>
				</select>
				<button>Send</button>
			</form>
		</div>
	);
};

export default Contact;
