import {Link} from "react-router-dom";

const About = () => {
	return (
		<div className="about">
			<h2 className="page-title"> Reference Picture DB</h2>
			<p>
				Welcome to my interactive civil engineering tool that allows you to
				quickly evaluate different facade and window designs in the early stages
				of building design. The tool utilizes data from various iterative
				Rhino/Grasshopper models and simulates them using the ICEbear plugin.
			</p>
			<p>
				The goal of The tool is to make the design process more efficient by
				providing real-time feedback on different facade and window designs. By
				simply inputting your building parameters and desired design options,
				you can quickly see how different designs affect factors such as energy
				efficiency, natural lighting, and thermal indoor climate.
			</p>
			<p>
				The tool is perfect for architects, engineers, and builders who want to
				streamline their design process while ensuring that they are creating
				sustainable, functional buildings. By using the tool, you can make
				informed decisions about your design options and ensure that your final
				building design meets your client's needs while also being
				environmentally responsible.
			</p>
			<p>
				In the modern building industry we understand the importance of
				early-stage design decisions, and my tool is designed to help you make
				the best choices for your project. Whether you're working on a small
				residential building or a large commercial project, the tool can help
				you evaluate different design options quickly and easily.
			</p>
		</div>
	);
};

export default About;
