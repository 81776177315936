import "./RangeSlider.css";
import useFetch from "./useFetch";
import {Link} from "react-router-dom";
import ImageRender from "./ImageRender";
import {useState} from "react";

const SimulationResults = (props) => {
	const filteredImages = props.filteredImages;
	const orientation = props.val7;
	// console.log("Simulation Results", filteredImages);

	const [isToggled, setIsToggled] = useState(false);

	return (
		<div className="imagecontainer">
			<img
				className="model-image"
				src={require(`./data/V3.00 Data/${filteredImages[0].img}`)}
				alt="Image couldn't load"
			/>

			<img
				className="tooltip"
				src={require(`./icons/tooltip.png`)}
				alt="Image couldn't load"
				title="For more information on performance indicators and simulation details go to the bottom of the page"
			/>

			<span
				style={{
					fontSize: 15,
					fontWeight: "bold",
					color: "#f1356d",
					position: "absolute",
					left: "20px",
					bottom: "15px",
					backgroundColor: "white",
					borderRadius: "5px",
					padding: "2px",
				}}
			>
				Orientation: {filteredImages[0].OrientationDirection}
			</span>

			<div className="outputcontainer">
				<button
					onClick={() => setIsToggled(!isToggled)}
					className="result-button"
				>
					{isToggled ? "Show Simple Results" : "Show Advanced Results"}
				</button>

				{isToggled ? (
					<span className="outputname">
						Useful Daylight Illuminance:
						<br />
						Thermal Comfort:
						<br />
						Heating / Electricity:
						<br />
						Window-to-Wall Ratio:
					</span>
				) : (
					<div className="output-alternative">
						<img
							className="icons"
							src={require(`./icons/daylight.png`)}
							alt="Image couldn't load"
							title="Useful Daylight Illuminance refers to how much useable natural daylight is entering the room"
						/>
						<p
							style={{
								color:
									Number(filteredImages[0].DaylightAutonomy) < 60
										? "red"
										: "black",
							}}
						>
							&nbsp;:&nbsp;{filteredImages[0].pointsUDI}/10
							&nbsp;&nbsp;&nbsp;&nbsp;
						</p>

						<img
							className="icons"
							src={require(`./icons/thermostat.png`)}
							alt="Image couldn't load"
							title="Thermal Indoor Climate Comfort"
						/>
						<p
							style={{
								color:
									Number(
										Number(filteredImages[0].ThermalComfortI) +
											Number(filteredImages[0].ThermalComfortII)
									).toFixed(1) < 95
										? "red"
										: "black",
							}}
						>
							:&nbsp;{filteredImages[0].pointsThermalComfort}/10
							&nbsp;&nbsp;&nbsp;&nbsp;
						</p>

						<img
							className="icons"
							src={require(`./icons/energy.png`)}
							alt="Image couldn't load"
							title="Energy need based on the simulation - This value is easily adjustable without too much change to the visual design"
						/>
						<p
							style={{
								color:
									Number(
										Number(filteredImages[0].Heating) +
											Number(filteredImages[0].Electricity)
									) > 41
										? "red"
										: "black",
							}}
						>
							&nbsp;:&nbsp;{filteredImages[0].pointsEnergyUse}/10
							&nbsp;&nbsp;&nbsp;&nbsp;
						</p>
					</div>
				)}

				{isToggled && (
					<span className="outputresult">
						<p
							style={{
								color:
									Number(filteredImages[0].DaylightAutonomy) < 60
										? "red"
										: "black",
							}}
						>
							{Number(filteredImages[0].DaylightAutonomy).toFixed(1)}
						</p>
						<p
							style={{
								color:
									Number(
										Number(filteredImages[0].ThermalComfortI) +
											Number(filteredImages[0].ThermalComfortII)
									).toFixed(1) < 95
										? "red"
										: "black",
							}}
						>
							{Number(
								Number(filteredImages[0].ThermalComfortI) +
									Number(filteredImages[0].ThermalComfortII)
							).toFixed(1)}
						</p>
						<p
							style={{
								color:
									Number(
										Number(filteredImages[0].Heating / 0.85) +
											Number(filteredImages[0].Electricity / 1.9)
									) > 41
										? "red"
										: "black",
							}}
						>
							{Number(filteredImages[0].Heating / 0.85).toFixed(1)} /&nbsp;
							{Number(filteredImages[0].Electricity / 1.9).toFixed(1)}
						</p>

						<p
							style={{
								color:
									Number(filteredImages[0].WWR * 100) < 20 ? "red" : "black",
							}}
						>
							{Number(filteredImages[0].WWR * 100).toFixed(1)}
						</p>
					</span>
				)}

				{isToggled ? (
					<span className="outputunit">
						% <br />
						%<br />
						kWh/&#13217;/y
						<br />%
					</span>
				) : (
					<span></span>
				)}
			</div>
		</div>
	);
};

export default SimulationResults;
