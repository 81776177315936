import {Link} from "react-router-dom";
import Recommendation from "./Recommendation";
// import Simple from "./data/referencepictures.json";
import useFetch from "./useFetch";
import ReferencePictures from "./ImagesFacadeTypology";
import "./ReferencePictures.css";
import {rpDrawings, rpPhotos} from "./FacadeTypologyDrawings";
import {useState, useEffect, useMemo} from "react";
/* eslint-disable no-unused-expressions */

const FacadeTypologiesList = () => {
	const {
		error,
		isPending,
		data: Simple,
	} = useFetch("http://localhost:8000/Simple");

	const [fileCat, setFileCat] = useState("");

	const [file, setFile] = useState(null);

	const filteredList = rpPhotos.filter((category) => {
		return category.title === fileCat.title;
	});

	return (
		<>
			<div className="facade-typologies">
				<h2 className="page-title"> Facade Typologies</h2>
			</div>
			<div className="modeltypes">
				<p className="description-text">Choose a Facade Typology</p>
			</div>

			<section className="page-container">
				{rpDrawings &&
					rpDrawings.map((fileCat, index) => (
						<div
							key={index}
							className="model-preview"
							onClick={() => setFileCat(fileCat)}
						>
							{
								(fileCat.type = "image" ? (
									<img className="models" src={fileCat.url} alt="" />
								) : (
									<video src={fileCat.url} muted />
								))
							}
							<p className="model-name-text">{fileCat.title}</p>
						</div>
					))}
			</section>

			{fileCat !== "" && (
				<section>
					<div className="GoToTools">
						<h2
							style={{
								paddingBottom: "20px",
							}}
						>
							{fileCat.title} Model
						</h2>
						<img className="models" src={fileCat.url} alt={fileCat.title} />
						<Link
							to="/Tool"
							style={{
								display: "block",
								fontWeight: "bold",
								fontSize: "1.0em",
								color: "white",
								backgroundColor: "#f1356d",
								borderRadius: "8px",
								padding: "8px",
								paddingLeft: "16px",
								paddingRight: "16px",
								float: "left",
							}}
						>
							Go To {fileCat.title} Model Tool
							<i className="arrow right" style={{marginLeft: "8px"}}></i>
						</Link>
					</div>
				</section>
			)}

			<section className="rp-container">
				<div className="rp-mediacontainer">
					{filteredList &&
						filteredList.map((file, index) => (
							<div
								key={index}
								className="rp-media"
								onClick={() => setFile(file)}
							>
								{
									(file.type = "image" ? (
										<img src={file.image} alt="" />
									) : (
										<video src={file.image} muted />
									))
								}
							</div>
						))}
				</div>

				<div
					className="popup-media"
					onClick={() => setFile(null)}
					style={{display: file ? "block" : "none"}}
				>
					<div className="kæmpeFUCKINGxContainer">
						<span className="kæmpeFUCKINGx" onClick={() => setFile(null)}>
							x
						</span>
					</div>

					{file?.type === "image" ? (
						<video src={file?.image} muted autoPlay controls />
					) : (
						<img src={file?.image} />
					)}
				</div>
			</section>

			<Recommendation />
		</>
	);
};
/* eslint-disable-line */
export default FacadeTypologiesList;
