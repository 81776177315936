import ImageRender from "./ImageRender";
// prettier-ignore
import {Page, Image, Text, View, Document, StyleSheet, PDFViewer, Font} from "@react-pdf/renderer";
import ReactPDF from "@react-pdf/renderer";
import {PDFDownloadLink} from "@react-pdf/renderer";
import Roboto from "./assets/Roboto/Roboto-Regular.ttf";

const styles = StyleSheet.create({
	page: {
		flexDirection: "column",
		backgroundColor: "#ffffff",
	},
	lastpage: {
		flexDirection: "column",
		margin: 20,
		backgroundColor: "#ffffff",
	},
	header: {
		marginHorizontal: 30,
		marginTop: 30,
		marginBottom: 10,
		height: 30,
		maxWidth: 700,
		textAlign: "center",
	},
	title: {
		fontSize: 18,
		textWeight: "bold",
		textAlign: "center",
	},
	section: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-around",
		maxWidth: 700,
		flexGrow: 1,
		flexWrap: "wrap",
	},
	subsection: {
		flexDirection: "column",
		alignItems: "flex-start",
		justifyContent: "flex-start",
		paddingHorizontal: 6,
		marginBottom: 10,
		maxWidth: 600,
		flexGrow: 1,
		textAlign: "left",
		borderBottom: "solid",
		borderBottomWidth: 2,
		borderColor: "black",
	},
	textcontainer: {
		flexDirection: "column",
		alignItems: "start",
		padding: 5,
		maxWidth: 275,
		flexGrow: 1,
		textAlign: "left",
	},
	text: {
		marginBottom: 10,
		fontSize: 10,
		textAlign: "left",
		fontFamily: "Helvetica",
	},
	resulttext: {
		fontSize: 10,
		color: "#808080",
		textAlign: "center",
		fontFamily: "Helvetica",
		textIndent: 10,
	},
	boldtext: {
		fontSize: 12,
		marginBottom: 5,
		fontWeight: "bolder",
		textAlign: "left",
		fontFamily: "Helvetica",
	},
	textDetails: {
		marginBottom: 10,
		marginLeft: 15,
		marginRight: 15,
		fontSize: 11,
		textAlign: "justify",
		fontFamily: "Helvetica",
	},
	textDetailsbold: {
		marginBottom: 10,
		marginLeft: 15,
		fontSize: 11,
		textAlign: "justify",
		// color: "#808080",
		// fontStyle: "italic",
		fontWeight: "bold",
		textAlign: "left",
		fontFamily: "Helvetica",
	},
	boldtextDetails: {
		fontSize: 14,
		marginBottom: 5,
		marginRight: 15,
		marginLeft: 15,
		textAlign: "justify",
		fontWeight: "bolder",
		textAlign: "left",
		fontFamily: "Helvetica",
	},
	imagecontainer: {
		flexDirection: "row",
		height: 200,
		Width: 640,
		justifyContent: "space-around",
		alignItems: "center",
		flexGrow: 1,
	},
	image: {
		marginBottom: 10,
		height: 150,
		width: 250,
		objectFit: "cover",
	},
	imageDetails: {
		marginBottom: 10,
		height: 170,
		width: 250,
		objectFit: "cover",
	},
	pageNumber: {
		position: "absolute",
		fontSize: 12,
		bottom: 30,
		left: 0,
		right: 0,
		textAlign: "center",
		color: "grey",
	},
});

const MyDocument = (props) => {
	const PDF = props.PDF;
	console.log("overview", PDF);

	const br = () => "\n";

	const PDFFile = () => {
		return (
			<Document>
				<Page wrap size="A4" style={styles.page}>
					<View style={styles.header}>
						<Text style={styles.title} fixed>
							Report Summary
						</Text>
					</View>

					<View style={styles.section}>
						{PDF.map((CarCards) => (
							<View wrap={false} style={styles.subsection}>
								<Image
									style={styles.image}
									src={require(`./data/V3.00 Data/${CarCards.img}`)}
								/>

								<View style={styles.textcontainer}>
									<Text style={styles.boldtext}>Input</Text>
									<Text style={styles.text}>
										Window Width:{" "}
										{CarCards.WindowNumber === "1" && (
											<Text style={styles.resulttext}>
												{Number(((1 - CarCards.Width * 2) * 5.6).toFixed(1))}
												{""} m
											</Text>
										)}
										{CarCards.WindowNumber === "2" && (
											<Text style={styles.resulttext}>
												{Number(
													(((1 - CarCards.Width * 2) * 5.6) / 2).toFixed(1)
												)}
												{""} m
											</Text>
										)}
										{CarCards.WindowNumber === "3" && (
											<Text style={styles.resulttext}>
												{Number(
													(((1 - CarCards.Width * 2) * 5.6) / 3).toFixed(1)
												)}
												{""} m
											</Text>
										)}
										{"\n"}
										Window Height: {""}
										<Text style={styles.resulttext}>
											{""}
											{Number(((1 - CarCards.Height * 2) * 2.8).toFixed(1))} m
										</Text>
										{"\n"}
										No. of Windows:{" "}
										<Text style={styles.resulttext}>
											{CarCards.WindowNumber}
										</Text>
										{"\n"}
										Window Type:
										{CarCards.WindowType === "1" && (
											<Text style={styles.resulttext}> 4-14Ar(90%)-lowE4</Text>
										)}
										{CarCards.WindowType === "2" && (
											<Text style={styles.resulttext}> 4S-16Ar(90%)-lowE4</Text>
										)}
										{CarCards.WindowType === "3" && (
											<Text style={styles.resulttext}>
												{" "}
												4SN-16Ar(90%)-4-16Ar(90%)-lowE4
											</Text>
										)}
										{"\n"}
										Orientation: <Text style={styles.resulttext}>South</Text>
										{"\n"}
										{/* Fix Orientation when that is implemented in the simulations */}
										Wall Offset:
										{CarCards.WallOffset === "0.3" && (
											<Text style={styles.resulttext}> Left </Text>
										)}
										{CarCards.WallOffset === "0.5" && (
											<Text style={styles.resulttext}> Center </Text>
										)}
										{CarCards.WallOffset === "0.7" && (
											<Text style={styles.resulttext}> Right </Text>
										)}
										{"\n"}
										Floor Offset:
										{CarCards.FloorOffset === "0.3" && (
											<Text style={styles.resulttext}> Bottom </Text>
										)}
										{CarCards.FloorOffset === "0.5" && (
											<Text style={styles.resulttext}> Center </Text>
										)}
										{CarCards.FloorOffset === "0.7" && (
											<Text style={styles.resulttext}> Top </Text>
										)}
									</Text>
									<Text style={styles.boldtext}>Results</Text>
									<Text style={styles.text}>
										Useful Daylight Illuminance:{" "}
										<Text style={styles.resulttext}>
											{CarCards.DaylightAutonomy} %
										</Text>
										{"\n"}
										Thermal Comfort:{" "}
										<Text style={styles.resulttext}>
											{Number(
												Number(CarCards.ThermalComfortI) +
													Number(CarCards.ThermalComfortII)
											).toFixed(1)}{" "}
											%
										</Text>
										{"\n"}
										Heating / Electricity:{" "}
										<Text style={styles.resulttext}>
											{Number(CarCards.Heating / 0.85).toFixed(1)} /
											{Number(CarCards.WWR / 1.9).toFixed(1)} kWh/m2/y
										</Text>
										{"\n"}
										Window-to-Wall Ratio:{" "}
										<Text style={styles.resulttext}>
											{Number(CarCards.WWR * 100).toFixed(1)} %
										</Text>
									</Text>
								</View>
							</View>
						))}
					</View>

					<Text
						style={styles.pageNumber}
						render={({pageNumber, totalPages}) =>
							`${pageNumber} / ${totalPages}`
						}
						fixed
					/>
				</Page>

				<Page wrap size="A4" style={styles.page}>
					<View style={styles.header}>
						<Text style={styles.title} fixed>
							Details
						</Text>
					</View>

					<View style={styles.lastpage}>
						<Text style={styles.boldtextDetails}>Report Description</Text>
						<Text style={styles.textDetails}>
							This is the report for a simulation done on a simple model using
							Reference Picture Database (RPD). RPD is a software application
							that offers a curated collection of photos showcasing various
							facade typologies. By selecting a desired reference picture, users
							can choose a corresponding pre-simulated model and explore
							multiple reference designs. The simulation process is facilitated
							by the Rhino/Grasshopper plug-in ICEbear V2.03, which allows for
							real-time presentation of results. The provided simulations cover
							performance indicators such as Useful Daylight Illuminance,
							thermal comfort (Fanger EN15251:2007), energy need, and
							window-to-wall ratio. This comprehensive analysis enables
							architects and designers to evaluate and compare different facade
							options effectively.
							{"\n"} {"\n"}
							Currently all results are based on the danish energyPlus
							weatherfile DNK.DRY2013.epw. The model has a single room with one
							facade exposed to the outdoors, as well as the following geometric
							measurements:
							{"\n"}
							{"\n"}
							<View style={{flexDirection: "row", marginBottom: 4}}>
								<Text>• {""}</Text>
								<Text style={styles.textDetails}>Room Height: 2.8 m</Text>
							</View>
							{"\n"}
							<View style={{flexDirection: "row", marginBottom: 4}}>
								<Text>• {""}</Text>
								<Text style={styles.textDetails}>Room Width: 5.6 m</Text>
							</View>
							{"\n"}
							<View style={{flexDirection: "row", marginBottom: 4}}>
								<Text>• {""}</Text>
								<Text style={styles.textDetails}>Room Depth: 6.0 m</Text>
							</View>
							{"\n"} {"\n"}
							Be cautious of regarding the shown results as definitive, as the
							simulations are carried out using generic input, which designers
							and engineers can tweak in a multitude of ways in the later stages
							of a project. This webapplication, however, has the purpose of
							giving an overall feel for the chosen performance metrics and
							range of choices associated with your intended design.
							{"\n"} {"\n"}
						</Text>

						<Text style={styles.boldtextDetails}>Performance Indicators</Text>
						<Text style={styles.textDetails}>
							<Text style={styles.textDetailsbold}>
								Useful Daylight Illuminance (UDI) {""}
							</Text>
							is a metric that is used to evaluate the quality and effectiveness
							of natural daylight in a building. It is a measure of the amount
							of daylight available at a particular location in a room, and
							whether that level of daylight is adequate for the visual tasks
							that are typically performed in that location. The measured point
							in all simulations performed in the website tool is set in the
							center of the room, 0.85 meters above floor level. UDI is
							expressed in a percentage measure based on the simulated Lux
							level, which is a unit of measurement for the amount of light that
							falls on a surface. {"\n"} It evaluates the percentage of time
							that a location in a room meets the daylight requirements for
							visual tasks. The recommended levels of Lux for visual tasks vary
							depending on the specific task, but to be considered useful
							daylight in these particular simulations the range is set from 200
							to 2000 lux.
							{"\n"} {"\n"}
							<Text style={styles.textDetailsbold}>Thermal Comfort {""}</Text>
							refers to the sum of predicted occupational time spent in either
							thermal class I or II as defined in EN15251:2007. The
							categorization is based on Fanger's indices PMV (Predicted Mean
							Vote) and PDD (Predicted Percentage Dissatisfied). Below is a
							graph depicting the correlation between PMV and PDD, as well as a
							table with further specifications on the thermal classes.
							{"\n"} {"\n"}
							<View style={styles.imagecontainer}>
								<Image
									style={styles.imageDetails}
									src={require(`./icons/Fanger.png`)}
								/>
								<Image
									style={styles.imageDetails}
									src={require(`./icons/Fanger2.png`)}
								/>
							</View>
							{"\n"} {"\n"}
						</Text>
					</View>

					<Text
						style={styles.pageNumber}
						render={({pageNumber, totalPages}) =>
							`${pageNumber} / ${totalPages}`
						}
						fixed
					/>
				</Page>

				<Page wrap size="A4" style={styles.page}>
					<View style={styles.lastpage}>
						<View style={{marginTop: 20}}>
							<Text style={styles.textDetails}>
								<Text style={styles.textDetailsbold}>Energy need {""}</Text>
								refers to the amount of energy required to maintain the indoor
								environmental conditions within a building at a desired level of
								comfort and functionality. The energy needed to run heating,
								ventilation, air conditioning systems, lighting, and other
								building systems that maintain the indoor environment
								contributes to the building's energy needs.
								{"\n"}
								The danish building regulations state that primary energy
								factors should be included depending on the type of need.
								Heating, which mainly consists of room heating has an energy
								factor of 0.85 (if district heating is implemented).
								Electricity, which covers fan power, cooling and lighting has an
								energy factor of 1.9. These energy factors are {""}
								<Text style={styles.textDetailsbold}>NOT {""}</Text>
								included in the results.
								{"\n"}
								{"\n"}
								<Text style={styles.textDetailsbold}>
									Window-to-Wall Ratio {""}
								</Text>
								is simply a number refering to the area ratio between the
								exterior wall and the window.
							</Text>

							<Text style={styles.boldtextDetails}>Simulation Details</Text>
							<Text style={styles.textDetails}>
								Read about the simulation details on the website by clicking the
								"about" tab in the navigation bar.
							</Text>
						</View>
					</View>

					<Text
						style={styles.pageNumber}
						render={({pageNumber, totalPages}) =>
							`${pageNumber} / ${totalPages}`
						}
						fixed
					/>
				</Page>
			</Document>
		);
	};

	return (
		<div>
			<PDFDownloadLink document={<PDFFile />} fileName="RPD_Report">
				{({loading}) =>
					loading ? (
						<button> Loading Document... </button>
					) : (
						<button> Download Report </button>
					)
				}
			</PDFDownloadLink>
		</div>
	);
};

export default MyDocument;
