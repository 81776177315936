import {useState} from "react";
import "./DropDownCSS.css";
import HowToUse from "./HowToUse";

const DropDownInfo = () => {
	const [wrap, setWrap] = useState(false);
	const [wrap2, setWrap2] = useState(false);
	const [wrap3, setWrap3] = useState(false);

	const handleWrapper = () => {
		setWrap((current) => !current);
	};
	const handleWrapper2 = () => {
		setWrap2((current) => !current);
	};
	const handleWrapper3 = () => {
		setWrap3((current) => !current);
	};

	return (
		<div
			style={{
				marginTop: "30px",
				borderWidth: "2px",
				borderColor: "black",
				backgroundColor: "#f1eced",
				padding: "20px",
				width: "1000px",
			}}
		>
			<h3>
				<button className="DropDownButton" onClick={handleWrapper}>
					How To Use <i className="arrow right" style={{marginLeft: "8px"}}></i>
				</button>
			</h3>
			<div className={wrap ? "wrapper-open" : "wrapper"}>
				<div className="inner">
					<HowToUse />
				</div>
			</div>

			<h3>
				<button className="DropDownButton" onClick={handleWrapper2}>
					Performance Indicators{" "}
					<i className="arrow right" style={{marginLeft: "8px"}}></i>
				</button>
			</h3>
			<div className={wrap2 ? "wrapper-open" : "wrapper"}>
				<div className="inner">
					<h3
						style={{
							display: "inline-block",
							fontWeight: "bold",
							fontSize: "1.3em",
							color: "white",
							color: "#f1356d",
							marginTop: "30px",
						}}
					>
						Performance Indicators
					</h3>
					<p>
						<span style={{fontWeight: "bold", color: "#f1356d"}}>
							Useful Daylight Illuminance (UDI) {""}
						</span>
						is a metric that is used to evaluate the quality and effectiveness
						of natural daylight in a building. It is a measure of the amount of
						daylight available at a particular location in a room, and whether
						that level of daylight is adequate for the visual tasks that are
						typically performed in that location. The measured point in all
						simulations performed in the website tool is set in the center of
						the room, 0.85 meters above floor level. UDI is expressed in a
						percentage measure based on the simulated Lux level, which is a unit
						of measurement for the amount of light that falls on a surface.{" "}
						{"\n"} It evaluates the percentage of time that a location in a room
						meets the daylight requirements for visual tasks. The recommended
						levels of Lux for visual tasks vary depending on the specific task,
						but to be considered useful daylight in these particular simulations
						the range is set from 200 to 2000 lux as per ICEbear's mandatory
						default setting.
					</p>
					<p
						style={{
							marginLeft: "40px",
							marginRight: "40px",
							marginBottom: "30px",
						}}
					>
						The threshold in which the UDI icon turns red is when the value goes
						below 60%. The danish regulations doesn't use this unit of measure,
						but instead demands a lower threshold of 300 lux in half of the room
						for half of the yearly occupation time.
					</p>
					<p>
						<span style={{fontWeight: "bold", color: "#f1356d"}}>
							Thermal Comfort {""}
						</span>
						refers to the sum of predicted occupational time spent in either
						thermal class I or II as defined in EN15251:2007. The categorization
						is based on Fanger's indices PMV (Predicted Mean Vote) and PDD
						(Predicted Percentage Dissatisfied). For example, having a Thermal
						Comfort percentage of 95% means that over 10% of the people present
						will be uncomfortable with the thermal environment 5% of the time.
					</p>
					<p
						style={{
							marginLeft: "40px",
							marginRight: "40px",
							marginBottom: "30px",
						}}
					>
						The threshold in which the thermal comfort icon turns red is when
						the result reaches values below 95%.
					</p>
					<p>
						<span style={{fontWeight: "bold", color: "#f1356d"}}>
							Energy Need {""}
						</span>
						refers to the amount of energy required to maintain the indoor
						environmental conditions within a building at a desired level of
						comfort and functionality. The energy needed to run heating,
						ventilation, air conditioning systems, lighting, and other building
						systems that maintain the indoor environment contributes to the
						building's energy needs. The danish building regulations state that
						primary energy factors should be included depending on the type of
						need. Heating, which mainly consists of room heating has an energy
						factor of 0.85 (if district heating is implemented). Electricity,
						which covers fan power, cooling and lighting has an energy factor of
						1.9. These energy factors are NOT included in the results.
					</p>
					<p
						style={{
							marginLeft: "40px",
							marginRight: "40px",
							marginBottom: "30px",
						}}
					>
						The threshold in which the energy icon turns red is when the energy
						need exceeds 41 kWh/m2/y, which is the danish regulations threshold
						for non-dwelling buildings.
					</p>
					<p>
						<span style={{fontWeight: "bold", color: "#f1356d"}}>
							Window-to-Wall Ratio {""}
						</span>
						is simply a number refering to the area ratio between the exterior
						wall and the window.
					</p>
				</div>
			</div>

			<h3>
				<button className="DropDownButton" onClick={handleWrapper3}>
					Simulation Details{" "}
					<i className="arrow right" style={{marginLeft: "8px"}}></i>
				</button>
			</h3>
			<div className={wrap3 ? "wrapper-open" : "wrapper"}>
				<div className="inner">
					<h3
						style={{
							display: "inline-block",
							fontWeight: "bold",
							fontSize: "1.3em",
							color: "white",
							color: "#f1356d",
							marginTop: "30px",
						}}
					>
						Simulation Details
					</h3>
					<p>
						This is the report for a simulation done on a simple model using
						Reference Picture Database (RPD). RPD is a software application that
						presents a collection of photos depicting typical facade typologies,
						whereafter you can choose a model associated with the desired
						reference pictures and work out multiple reference designs. These
						models are pre-simulated using the Rhino/Grasshopper plug-in ICEbear
						V2.03, which makes it possible to quickly present real time results.
						This includes Useful Daylight Illuminance, thermal comfort (Fanger
						EN15251:2007), energy need, as well as window-to-wall ratio.
					</p>
					<p>
						Currently all results are based on the danish energyPlus weatherfile
						DNK.DRY2013.epw. All simulation types are considered "office
						buildings" at the current stage of the website. The model has a
						single room with one facade exposed to the outdoors, as well as the
						following geometric measurements:
					</p>
					<li>Room Height: 2.8 m</li>
					<li>Room Width: 5.6 m</li>
					<li>Room Depth: 6.0 m</li>
					<li>Floor Area: 33.6 m2</li>
					<p>
						Be cautious of regarding the shown results as definitive, as the
						simulations are carried out using very generic input, which
						designers and engineers can tweak in a multitude of ways. This
						webapplication, however, has the purpose of giving an overall
						feeling of the scope and range of facade design choices.
					</p>
				</div>
			</div>
		</div>
	);
};

export default DropDownInfo;
