import {useState, useEffect} from "react";

const useFetch = (url) => {
	const [data, setData] = useState(null);
	const [isPending, setIsPending] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		// const abortContent = new AbortController();

		fetch(url)
			.then((res) => {
				if (!res.ok) {
					throw Error("Could not fetch the data for that resource");
				}
				return res.json();
			})
			.then((data) => {
				setData(data);
				setIsPending(false);
				setError(null);
			})
			.catch((err) => {
				// if (err.name === "AbortError") {
				// 	console.log("fetch aborted");
				// } else {
				setIsPending(false);
				setError(err.message);
				// }
			});

		// return () => abortContent.abort();
	}, [url]);

	return {data, isPending, error};
};

export default useFetch;
