import {useState, useEffect, useRef, Fragment} from "react";
import "./RangeSlider.css";
import Tool from "./Tool";
import {click} from "@testing-library/user-event/dist/click";
import SimulationResults from "./SimulationResults";
import Sliders from "./Sliders";
import React from "react";
import ReactDOM from "react-dom";
import PDFGenerator from "./PDFGenerator";
import {Link} from "react-router-dom";
/* eslint-disable no-unused-expressions */

const ImageRender = (props) => {
	const images = props.images;
	// console.log(props, images);

	const [val1, setVal1] = useState("0.16");
	const [val2, setVal2] = useState("0.16");
	const [val3, setVal3] = useState("0.5");
	const [val4, setVal4] = useState("0.5");
	const [val5, setVal5] = useState("1");
	const [val6, setVal6] = useState("1");
	const [val7, setVal7] = useState("0");
	// const [overviewName, setOverviewName] = useState("");

	const filteredImages = images.filter((image) => {
		return (
			image.Width === val1 &&
			image.Height === val2 &&
			image.WallOffset === val3 &&
			image.FloorOffset === val4 &&
			image.WindowNumber === val5 &&
			image.WindowType === val6 &&
			image.Orientation === val7
			// DataV1-2 is without image.Orientation
		);
	});

	localStorage.setItem("objectState", JSON.stringify(filteredImages));
	const savedState = JSON.parse(localStorage.getItem("objectState"));
	const [savedObject, setSavedObject] = useState([]);
	const PDF = savedObject;

	console.log("pdf", PDF);
	console.log("savedState", savedState);
	console.log("savedObjects", savedObject);

	const handleClick = () => {
		setSavedObject((current) => [...current, ...savedState]);
	};
	const handleDelete = (img) => {
		const newObject = savedObject.filter((overview) => overview.img !== img);
		setSavedObject(newObject);
	};

	return (
		<div>
			<section className="flexcontainer">
				{filteredImages && (
					<SimulationResults filteredImages={filteredImages} val7={val7} />
				)}

				<div className="columncontainer">
					<div className="variableheader">
						<h2 className="variables">Variables</h2>
					</div>
					<div className="slidercontainer">
						<p className="variablename">Width</p>
						<input
							type="range"
							min="0.01"
							max="0.26"
							step="0.05"
							value={val1}
							onChange={(e) => setVal1(e.target.value)}
							style={{backgroundColor: `rgb(val-69, val, val-69)`}}
							className={`slider ${
								val1 > 69 ? "slider-reverse-69" : "slider-reverse-0"
							}`}
						/>
						<p className="rangevalue">
							{val5 === "1" && (
								<span> {Number(((1 - val1 * 2) * 5.6).toFixed(1))}m</span>
							)}
							{val5 === "2" && (
								<span> {Number((((1 - val1 * 2) * 5.6) / 2).toFixed(1))}m</span>
							)}
							{val5 === "3" && (
								<span> {Number((((1 - val1 * 2) * 5.6) / 3).toFixed(1))}m</span>
							)}
						</p>
					</div>

					<div className="slidercontainer">
						<p className="variablename">Height</p>
						<input
							type="range"
							min="0.01"
							max="0.26"
							step="0.05"
							value={val2}
							onChange={(e) => setVal2(e.target.value)}
							style={{backgroundColor: `rgb(val-69, val, val-69)`}}
							className={`slider ${
								val2 > 69 ? "slider-reverse-69" : "slider-reverse-0"
							}`}
						/>
						<p className="rangevalue">
							<span> {Number(((1 - val2 * 2) * 2.8).toFixed(1))}m</span>
						</p>
					</div>

					<div className="slidercontainer">
						<p className="variablename">Wall Offset</p>
						<input
							type="range"
							min="0.3"
							max="0.7"
							step="0.2"
							value={val3}
							onChange={(e) => setVal3(e.target.value)}
							style={{backgroundColor: `rgb(val-69, val, val-69)`}}
							className={`slider ${val3 > 69 ? "slider-69" : "slider-0"}`}
						/>
						<p className="rangevalue">
							{val3 === "0.3" && <span> Left </span>}
							{val3 === "0.5" && <span> Center </span>}
							{val3 === "0.7" && <span> Right </span>}
						</p>
					</div>

					<div className="slidercontainer">
						<p className="variablename">Floor Offset</p>
						<input
							type="range"
							min="0.5"
							max="0.7"
							step="0.2"
							value={val4}
							onChange={(e) => setVal4(e.target.value)}
							style={{backgroundColor: `rgb(val-69, val, val-69)`}}
							className={`slider ${val4 > 69 ? "slider-69" : "slider-0"}`}
						/>
						<p className="rangevalue">
							{val4 === "0.3" && <span> Bottom </span>}
							{val4 === "0.5" && <span> Center </span>}
							{val4 === "0.7" && <span> Top </span>}
						</p>
					</div>

					<div className="slidercontainer">
						<p className="variablename">No. of Windows</p>
						<input
							type="range"
							min="1"
							max="3"
							step="1"
							value={val5}
							onChange={(e) => setVal5(e.target.value)}
							style={{backgroundColor: `rgb(val-69, val, val-69)`}}
							className={`slider ${val5 > 69 ? "slider-69" : "slider-0"}`}
						/>
						<p className="rangevalue">
							<span>{val5}</span>
						</p>
					</div>

					<div className="orientationcontainer">
						<button
							style={{
								opacity: val7 === "0" ? "1.0" : "0.6",
							}}
							onClick={() => setVal7("0")}
						>
							South
						</button>
						<button
							style={{
								opacity: val7 === "90" ? "1.0" : "0.6",
							}}
							onClick={() => setVal7("90")}
						>
							West
						</button>
						<button
							style={{
								opacity: val7 === "180" ? "1.0" : "0.6",
							}}
							onClick={() => setVal7("180")}
						>
							North
						</button>
						<button
							style={{
								opacity: val7 === "270" ? "1.0" : "0.6",
							}}
							onClick={() => setVal7("270")}
						>
							East
						</button>
					</div>

					<div className="slidercontainer">
						<p className="variablename">WindowType</p>

						<div className="windowtype">
							<select value={val6} onChange={(e) => setVal6(e.target.value)}>
								<option value="1">2-layer Window</option>
								<option value="2">2-layer window w. medium coating</option>
								<option value="3">3-layer window w. dark coating</option>
							</select>
						</div>
					</div>

					<div className="toolbuttons">
						<button
							onClick={handleClick}
							id="saveButton"
							className="toolbutton"
						>
							Save to overview
						</button>
					</div>
				</div>
			</section>

			<section>
				{savedObject.length >= 1 && (
					<div className="overview">
						<h2 className="page-title">Overview</h2>
						{savedObject.map((overview, id) => (
							<div className="overviewcontainer" key={overview.img}>
								<div key={overview.id}>
									<img
										className="overviewimage"
										src={require(`./data/V3.00 Data/${overview.img}`)}
										alt="Image couldn't load"
									/>

									{/* <input
										className="overviewnaming"
										type="text"
										placeholder="Name Design"
										required
										value={overview.name}
										onChange={(e) => setOverviewName(e, overview.name)}
									/> */}
								</div>

								<div className="output-overviewcontainer">
									<span className="outputname-overview">
										Useful Daylight Illuminance:
										<br />
										Thermal Comfort:
										<br />
										Heating / Electricity:
										<br />
										Window-to-wall Ratio:
									</span>

									<span className="outputresult-overview">
										<p
											style={{
												color:
													Number(overview.DaylightAutonomy) < 60
														? "red"
														: "black",
											}}
										>
											{Number(overview.DaylightAutonomy).toFixed(1)}
										</p>
										<p
											style={{
												color:
													Number(
														Number(overview.ThermalComfortI) +
															Number(overview.ThermalComfortII)
													).toFixed(1) < 95
														? "red"
														: "black",
											}}
										>
											{Number(
												Number(overview.ThermalComfortI) +
													Number(overview.ThermalComfortII)
											).toFixed(1)}
										</p>
										<p
											style={{
												color:
													Number(
														Number(filteredImages[0].Heating / 0.85) +
															Number(filteredImages[0].Electricity / 1.9)
													) > 41
														? "red"
														: "black",
											}}
										>
											{Number(overview.Heating / 0.85).toFixed(1)} /&nbsp;
											{Number(overview.Electricity / 1.9).toFixed(1)}
										</p>

										<p
											style={{
												color:
													Number(overview.WWR * 100) < 20 ? "red" : "black",
											}}
										>
											{Number(overview.WWR * 100).toFixed(1)}
										</p>
									</span>

									<span className="outputunit-overview">
										% <br />
										%<br />
										kWh/&#13217;/y
										<br />%
									</span>
								</div>

								<div className="outputpoints-overview">
									<div className="outputpoints-overview2">
										<img
											className="iconsalt"
											src={require(`./icons/daylight.png`)}
											alt="Image couldn't load"
										/>
										<p
											style={{
												color:
													Number(overview.DaylightAutonomy) < 60
														? "red"
														: "black",
											}}
										>
											&nbsp;: {overview.pointsUDI}/10
										</p>
									</div>
									<div className="outputpoints-overview2">
										<img
											className="iconsalt"
											src={require(`./icons/thermostat.png`)}
											alt="Image couldn't load"
										/>
										<p
											style={{
												color:
													Number(
														Number(overview.ThermalComfortI) +
															Number(overview.ThermalComfortII)
													).toFixed(1) < 95
														? "red"
														: "black",
											}}
										>
											:&nbsp;{overview.pointsThermalComfort}/10{" "}
										</p>
									</div>
									<div className="outputpoints-overview2">
										<img
											className="iconsalt"
											src={require(`./icons/energy.png`)}
											alt="Image couldn't load"
										/>
										<p
											style={{
												color:
													Number(
														Number(filteredImages[0].Heating / 0.85) +
															Number(filteredImages[0].Electricity / 1.9)
													) > 41
														? "red"
														: "black",
											}}
										>
											&nbsp;:&nbsp;{overview.pointsEnergyUse}/10
										</p>
									</div>
								</div>

								<button
									onClick={() => handleDelete(overview.img)}
									className="delete-button"
								>
									Delete
								</button>
							</div>
						))}

						{PDF && <PDFGenerator PDF={PDF} />}
					</div>
				)}
			</section>
		</div>
	);
};

/* eslint-disable-line */
export default ImageRender;
