import {Link} from "react-router-dom";

const Navbar = () => {
	return (
		<nav
			className="navbar"
			style={{
				backgroundColor: "#f1eced",
			}}
		>
			<Link
				to="/"
				style={{
					color: "#f1356d",
					fontSize: "1.8em",
					fontWeight: "bold",
				}}
			>
				Reference Picture DB
			</Link>
			<div className="links">
				<Link
					to="/facadetypologies"
					style={{
						fontSize: "1.1em",
						color: "white",
						backgroundColor: "#f1356d",
						borderRadius: "8px",
					}}
				>
					Facade Typologies
				</Link>
				<Link to="/imagesearch">Image Search</Link>
				<Link to="/about">About</Link>
				<Link to="/contact">Contact</Link>
				<Link
					to="/"
					style={{
						fontSize: "1.1em",
						color: "white",
						backgroundColor: "#f1356d",
						borderRadius: "8px",
					}}
				>
					Home
				</Link>
			</div>
		</nav>
	);
};

export default Navbar;
