import {useState} from "react";

const Recommendation = () => {
	const [comment, setComment] = useState("");

	const handleSubmit = (e) => {
		e.preventDefault();
		const recommendation = {comment};

		fetch("https://simonvu.dk/recommendation/", {
			method: "POST",
			headers: {"Content-Type": "application/json"},
			body: JSON.stringify(recommendation),
		});
	};

	return (
		<div className="recommend">
			<form noValidate onSubmit={handleSubmit}>
				<label>Recommend a model type</label>
				<input
					id="message"
					type="text"
					required
					value={comment}
					onChange={(e) => setComment(e.target.value)}
				/>
				<button onClick={() => setComment("")}>Send</button>
			</form>
		</div>
	);
};

export default Recommendation;
